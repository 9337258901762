<template>
  <div class="tabs">
    <div class="topSearch">
      <el-input
        placeholder="请输入"
        size="small"
        class="selectBox input-with-select"
        v-model="pageParams.queryWord"
        style="width: 300px"
      >
        <el-select
          v-model="pageParams.queryType"
          slot="prepend"
          placeholder="请选择"
          style="width: 100px"
        >
          <el-option label="设备名称" value="1"></el-option>
          <el-option label="所在位置" value="2"></el-option>
          <el-option label="监测点位" value="3"></el-option>
        </el-select>
      </el-input>
      数据类型
      <el-select
        class="selectBox"
        size="small"
        placeholder="请选择"
        v-model="pageParams.dataType"
      >
        <el-option
          v-for="item in dataTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="searchBtn">
        <el-button type="primary" size="small" @click="getList('reset')"
          >查询</el-button
        >
        <el-button type="info" plain size="small" @click="reset()"
          >重置</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      class="tableStyle"
      height="calc(100vh - 304px)"
    >
      <el-table-column label="序号" type="index" width="52"> </el-table-column>
      <el-table-column
        v-for="col in head"
        :key="col.prop"
        :prop="col.prop"
        :label="col.label"
        align="left"
        show-overflow-tooltip
        :sortable="col.sorts"
        :width="col.widths"
      >
      </el-table-column>
      <el-table-column label="阈值设置" width="80" align="center">
        <template slot-scope="{ row }">
          <span class="linkBtn" @click="setUp(row)">设置</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageParams.current"
      :page-sizes="[30, 50, 100]"
      :page-size="pageParams.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="TablePages"
    >
    </el-pagination>
    <el-dialog
      :visible.sync="dialogVisible"
      width="340px"
      top="20vh"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <div class="dialogTitles"><span></span> <b>阈值设置</b></div>
      </template>
      <el-form
        ref="form"
        label-position="top"
        :model="form"
        :rules="yuzhirRule"
      >
        <el-form-item label="高高报值" prop="hhValue">
          <el-input v-model="form.hhValue" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="高报值" prop="hvalue">
          <el-input v-model="form.hvalue" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item v-if="form.dataType != '温度'" label="低报值" prop="lvalue">
          <el-input v-model="form.lvalue" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item v-if="form.dataType != '温度'" label="低低报值" prop="llValue">
          <el-input v-model="form.llValue" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div class="btnGroup">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveSet()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
export default {
  name: "thresholdSet",
  components: {},
  data() {
    let hhValueVia = (rule, value, callback) => {
      if (value != null && value != "") {
        if(/^(?:\d{0,5}|0(?!(?:\.0{0,6})?$))?(?:\.\d{0,4})?$/.test(value) == false){
            callback(new Error("只能输入小数点前5位，后4位的数字"));
        }else
        if (
          this.form.hvalue &&
          Number(value) <= Number(this.form.hvalue)
        ) {
          callback(new Error("输入值不能小于等于高报"));
        } else if (
          this.form.lvalue &&
          Number(value) <= Number(this.form.lvalue)
        ) {
          callback(new Error("输入值不能小于等于低报"));
        } else if (
          this.form.llValue &&
          Number(value) <= Number(this.form.llValue)
        ) {
          callback(new Error("输入值不能小于等于低低报"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let hValueVia = (rule, value, callback) => {
      if (value != null && value != "") {
        if (
          this.form.hhValue &&
          Number(value) >= Number(this.form.hhValue)
        ) {
          callback(new Error("输入值不能大于等于高高报"));
        } else if (
          this.form.lvalue &&
          Number(value) <= Number(this.form.lvalue)
        ) {
          callback(new Error("输入值不能小于等于低报"));
        } else if (
          this.form.llValue &&
          Number(value) <= Number(this.form.llValue)
        ) {
          callback(new Error("输入值不能小于等于低低报"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let llValueVia = (rule, value, callback) => {
      if (value != null && value != "") {
        if (
          this.form.hhValue &&
          Number(value) >= Number(this.form.hhValue)
        ) {
          callback(new Error("输入值不能大于等于高高报"));
        } else if (
          this.form.hvalue &&
          Number(value) >= Number(this.form.hvalue)
        ) {
          callback(new Error("输入值不能大于等于高报"));
        } else if (
          this.form.lvalue &&
          Number(value) >= Number(this.form.lvalue)
        ) {
          callback(new Error("输入值不能大于等于低报"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let lValueVia = (rule, value, callback) => {
      if (value != null && value != "") {
        if (
          this.form.hhValue &&
          Number(value) >= Number(this.form.hhValue)
        ) {
          callback(new Error("输入值不能大于等于高高报"));
        } else if (
          this.form.hvalue &&
          Number(value) >= Number(this.form.hvalue)
        ) {
          callback(new Error("输入值不能大于等于高报"));
        } else if (
          this.form.llValue &&
          Number(value) <= Number(this.form.llValue)
        ) {
          callback(new Error("输入值不能小于等于低低报"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      pageParams: {
        current: 1,
        size: 100,
        dataType: "",
        queryType: "1",
        queryWord: "",
      },
      tableData: [],
      head: [
        { prop: "monitorPointName", label: "监测点位" },
        { prop: "unit", label: "单位", widths: "80px" },
        { prop: "dataType", label: "数据类型" },
        { prop: "important", label: "重点监测", widths: "80px" },
        { prop: "stationName", label: "所属站点/管网" },
        { prop: "equipmentName", label: "设备名称" },
        { prop: "soleCode", label: "设备唯一码" },
        { prop: "thresholdInfo", label: "阈值详情" },
      ],
      total: 0,
      dataTypeList: [],
      form: {
        llValue: "",
        hhValue: "",
        lvalue: "",
        hvalue: "",
        monitoringPointId: "",
        id: "",
        dataType: "",
      },
      dialogVisible: false,
      yuzhirRule: {
        hhValue: [{ required: false, validator: hhValueVia, trigger: "blur" }],
        hvalue: [{ required: false, validator: hValueVia, trigger: "blur" }],
        lvalue: [{ required: false, validator: lValueVia, trigger: "blur" }],
        llValue: [{ required: false, validator: llValueVia, trigger: "blur" }],
      },
    };
  },
  mounted() {
    // 数据类型
    scadaApi.dataTypeOptions({ names: "CollectionPointDataType" }).then((res) => {
      this.dataTypeList = res.data;
    });
    //
    this.getList();
  },
  methods: {
    getList(reset) {
      if (reset) {
        this.pageParams.current = 1;
        this.pageParams.size = 30;
      }
      scadaApi.thresholdConfigPage(this.pageParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    reset() {
      this.pageParams.current = 1;
      this.pageParams.size = 30;
      this.pageParams.dataType = "";
      this.pageParams.queryType = "1";
      this.pageParams.queryWord = "";
      this.getList();
    },
    setUp(data) {
      if (data.thresholdId) {
        // 查询单条
        scadaApi.thresholdConfigId({ id: data.thresholdId }).then((res) => {
          res.data.dataType = data.dataType;
          this.form = res.data;
          this.dialogVisible = true;
        });
      } else {
        // 新增
        this.form.dataType = data.dataType;
        this.form.monitoringPointId = data.monitorPointId;
        this.dialogVisible = true;
      }
    },
    closeDialog() {
      this.form = {
        llValue: "",
        hhValue: "",
        lvalue: "",
        hvalue: "",
        monitoringPointId: "",
        id: "",
        dataType: "",
      };
      this.$refs.form.clearValidate()
      this.$refs.form.resetFields()
    },
    saveSet() {
        let that = this
        this.$refs.form.validate(valid => {
            if(valid){
                let apis = this.form.id ? scadaApi.thresholdConfigUpdate : scadaApi.thresholdConfigSave
                apis(this.form).then(res => {
                    that.$message.success(res.msg)
                    that.dialogVisible = false
                    that.getList()
                })
            }
        })
    },
    handleSizeChange(e) {
      this.pageParams.size = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageParams.current = e;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.input-with-select .el-input-group__prepend {
  background-color: #fff;
  color: #0f0f0f;
}
::v-deep.el-form--label-top .el-form-item__label {
  padding: 0;
  color: #0f0f0f;
  line-height: 16px;
}
::v-deep.el-form-item {
  margin-bottom: 16px;
}
.btnGroup {
  border-top: 1px solid #ebebeb;
  padding-top: 12px;
  text-align: center;
}
.linkBtn {
  cursor: pointer;
  color: #1082ff;
}
.tabs {
  margin: 10px;
  background-color: #fff;
  height: calc(100vh - 206px);
  padding: 16px 10px;
  .topSearch {
    color: #0f0f0f;
    padding: 0px 0px 16px 10px;
    .selectBox {
      width: 200px;
      margin: 0 30px 0 0;
    }
    .searchBtn {
      display: inline-block;
    }
  }
}
.TablePages {
  margin: 16px 0;
  padding-left: 0;
}
</style>